import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import type { Department } from '@/types/department'
import type { Company } from '@/types/company'
import { Button, AnchorButton } from '@/components/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { Breadcrumb } from '@/components/Breadcrumb'
import { SortableList } from '@/components/SortableList'
import { Select } from '@/components/Select'

type Props = {
  current_user: User
  departments: Department[]
  company_id?: string
  company_options?: any[]
}

const DepartmentsIndexPage: React.FC<Props> = ({
  current_user,
  departments,
  company_id,
  company_options,
}) => {
  const title = '部署設定'
  const isSystemAdmin = current_user.role === 'system_admin'
  const [companyId, setCompanyId] = React.useState(
    isSystemAdmin ? company_id || current_user.company_id : null
  )

  const [cards, setCards] = React.useState([
    ...(companyId ? departments.filter((d) => d.company_id === Number(companyId)) : departments),
  ])

  const [cardsAll, setCardsAll] = React.useState([...departments])

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const canMove = React.useCallback((card: Department) => {
    return (
      card.company_id === current_user.company_id &&
      (current_user.role === 'system_admin' || current_user.role === 'admin')
    )
  })

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      const hoverCard = cards[hoverIndex]

      setCards((prevCards: Department[]) => {
        const dragCard = prevCards[dragIndex]
        const newCards = [...prevCards]
        newCards.splice(dragIndex, 1)
        newCards.splice(hoverIndex, 0, dragCard)
        return newCards
      })

      if (isSystemAdmin) {
        const dragIndexAll = cardsAll.findIndex((c) => c.id === dragCard.id)
        const hoverIndexAll = cardsAll.findIndex((c) => c.id === hoverCard.id)

        setCardsAll((prevCards: Department[]) => {
          const dragCard = prevCards[dragIndexAll]
          const newCards = [...prevCards]
          newCards.splice(dragIndexAll, 1)
          newCards.splice(hoverIndexAll, 0, dragCard)
          return newCards
        })
      }
    },
    [setCards]
  )

  const dropCard = React.useCallback(() => {
    cards.forEach((card, index) => {
      if (!canMove(card)) {
        return
      }

      fetch(`/departments/${card.id}.json`, {
        method: 'PATCH',
        body: JSON.stringify({ name: card.name, order: index + 1 }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
      }).catch(() => {
        alert('更新に失敗しました')
      })
    })
  }, [cards])

  const deleteCard = React.useCallback(
    (id: number) => {
      const deleteItem = cards.find((card) => card.id === id)
      const name = deleteItem.name
      if (!confirm(name + 'を削除してもよろしいですか？')) {
        return
      }
      fetch(`/departments/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
      })
        .then(() => {
          location.href = '/departments'
        })
        .catch((e) => {
          alert('削除に失敗しました')
        })
    },
    [cards]
  )

  const renderItem = React.useCallback(
    (card: Department) => {
      const path =
        companyId && Number(companyId) !== current_user.company_id
          ? `/departments/${card.id}/touki_account/edit?company_id=${companyId}`
          : `/departments/${card.id}/touki_account/edit`

      return (
        <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
          {current_user.role === 'system_admin' || current_user.role === 'admin' ? (
            <>
              <a
                className="flex border border-transparent items-strech w-full py-2 px-4 leading-normal text-sm cursor-pointer"
                href={`/departments/${card.id}/edit`}
              >
                {card.name}
              </a>
              {current_user.company.touki_enabled === true &&
                (current_user.role === 'admin' || current_user.role === 'system_admin') && (
                  <AnchorButton
                    className="text-primary-font"
                    prefix={<SettingsOutlinedIcon fontSize="small" />}
                    outline
                    size="small"
                    variant="primary"
                    href={path}
                  >
                    <span className="text-primary-font hidden md:block">登記情報</span>
                  </AnchorButton>
                )}
              {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
                <Button
                  className="text-primary-font"
                  prefix={<DeleteOutlined fontSize="small" />}
                  outline
                  size="small"
                  variant="primary"
                  onClick={() => {
                    deleteCard(card.id)
                  }}
                >
                  <span className="text-primary-font hidden md:block">削除</span>
                </Button>
              )}
            </>
          ) : (
            <>
              <span className="flex border border-transparent items-strech w-full py-2 px-4 leading-normal text-sm">
                {card.name}
              </span>
              <AnchorButton
                className="text-primary-font"
                prefix={<SettingsOutlinedIcon fontSize="small" />}
                outline
                size="small"
                variant="primary"
                href={`/departments/${card.id}/touki_account/edit`}
              >
                <span className="text-primary-font hidden md:block">登記情報</span>
              </AnchorButton>
            </>
          )}
        </td>
      )
    },
    [moveCard, dropCard, companyId]
  )

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['部署設定']} />}
      submenuCategory="settings"
    >
      <div className="flex flex-wrap items-end justify-between m-1 mr-2">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {departments.length.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">件</span>
        </div>
        {isSystemAdmin && (
          <div className="flex items-end gap-2">
            <div className="w-full">
              <div className="my-3 text-sm leading-none text-black-base whitespace-nowrap">
                企業
              </div>
              <Select
                className="w-full md:py-[7px]"
                hasBlank
                blankLabel="---"
                value={companyId}
                options={company_options}
                onChange={(e) => {
                  const value = e.target.value
                  const departments = cardsAll.filter((c) =>
                    value ? c.company_id === Number(value) : c
                  )
                  setCompanyId(value)
                  setCards(departments)
                }}
              />
            </div>
            <AnchorButton
              className="text-primary-font"
              prefix={<AddOutlinedIcon fontSize="small" />}
              outline
              size="small"
              variant="primary"
              href="/departments/new"
            >
              <span className="hidden md:inline">新規部署登録</span>
            </AnchorButton>
          </div>
        )}
        {current_user.role === 'admin' && (
          <AnchorButton
            className="text-primary-font"
            prefix={<AddOutlinedIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href="/departments/new"
          >
            <span className="hidden md:inline">新規部署登録</span>
          </AnchorButton>
        )}
      </div>
      <SortableList<Department>
        cards={cards}
        canMove={canMove}
        moveCard={moveCard}
        dropCard={dropCard}
        deleteCard={deleteCard}
        renderItem={renderItem}
      ></SortableList>
    </Base>
  )
}

export default DepartmentsIndexPage
