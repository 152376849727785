import { Breadcrumb } from '@/components/Breadcrumb'
import { Button } from '@/components/Button'
import Base from '@/components/Layout/Base'
import { Pagination } from '@/components/Pagination'
import { Select } from '@/components/Select'
import { Table } from '@/components/Table'
import { Pagy } from '@/types/pagy'
import type { AttachedFile, Transportation } from '@/types/property'
import { User } from '@/types/user'
import { cammedFormat } from '@/utils/cammedFormat'
import { Tab } from '@headlessui/react'
import { Option, ChildOption } from '@/components/DropDown/Index'
import SearchModal from '@/components/Page/MarketData/SearchModal'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

import * as React from 'react'

type MarketDatum = {
  id: number
  no: number
  hashid: string
  data_type: 'for_sale' | 'for_rent'
  prefecture: string
  city: string
  town: string
  chome: string
  property_type: string //物件種目
  building_type: string //物件タイプ
  custom_building_type: string //物件タイプ(ユーザー入力)
  status: 'open' | 'close' // 状態: 募集中/成約
  name: string // 物件名
  publication_date: string // 掲載日
  contract_date: string // 成約日
  area_m3: string // 敷地面積
  effective_area: string // 有効敷地面積
  total_floor_area: string // 延床面積
  occupied_area: string // 専有面積
  floors_above_ground: number // 建物階数
  floor: string //所在階
  floor_type: number //階層
  floor_type_text: string //階層
  floors_under_ground: number // 地下
  number_of_units: number // 総戸数
  floor_plan: string // 間取り
  building_structure: string // 構造
  building_date: string // 施工年月
  contractor: string //施工会社
  elevator: string // エレベーター
  auto_lock: string // オートロック
  current_rent: number // 現況賃料(税込)月額
  current_noi: number // 現況NOI / 月額
  full_prospective_rent: number // 満室想定賃料(税込)/ 月額
  full_prospective_noi: number // 満室想定NOI / 月額
  sale_price: number // 売出価格
  sale_prospective_yield: number // 売出表面利回り
  sale_prospective_noi_yield: number // 売出NOI利回り
  contract_prospective_noi_yield: number // 成約NOI利回り
  building_price: number // 成約価格
  prospective_yield: number // 成約表面利回り
  transportations: Transportation[] // 交通
}

type Props = {
  current_user: User
  pagy: Pagy
  market_datum: MarketDatum[]
  attached_files: { [key: number]: AttachedFile }
  prefecture_options: Option[]
  transportation_options: Option[]
  station_options: ChildOption[]
  floor_plan_options: any[]
  contractor_options: any[]
  room_type_options: any[]
}

const Index: React.FC<Props> = ({
  current_user,
  pagy,
  market_datum,
  attached_files,
  prefecture_options,
  transportation_options,
  station_options,
  floor_plan_options,
  contractor_options,
  room_type_options,
}) => {
  const searchParams = new URLSearchParams(location.search)
  const allTabs = ['for_sale', 'for_rent'] as const
  type TabType = (typeof allTabs)[number]
  const tab: TabType = (searchParams.get('data_type') as TabType) || 'for_sale'
  const title = tab === 'for_rent' ? '周辺賃貸事例一覧' : '周辺売買事例一覧'

  const onChangeTab = (index: number) => {
    location.search = `data_type=${allTabs[index]}`
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {pagy.count.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagy.from}～{pagy.to}件 表示
          </span>
          <span className="text-gray-800 text-sm mt-0.5 ml-2">表示件数：</span>
          <Select
            className="w-[100px] h-9"
            options={[
              {
                text: '20件',
                value: 20,
              },
              {
                text: '50件',
                value: 50,
              },
              {
                text: '100件',
                value: 100,
              },
            ]}
            value={searchParams.get('page_length') || '20'}
            onChange={(e) => {
              const searchParams = new URLSearchParams(location.search)
              searchParams.set('page_length', e.target.value)
              location.search = searchParams.toString()
            }}
          />
          <span className="ml-2">{<Pagination pagy={pagy} />}</span>
        </div>
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <SearchModal
            title="条件絞り込み"
            prefecture_options={prefecture_options}
            transportation_options={transportation_options}
            station_options={station_options}
            floor_plan_options={floor_plan_options}
            contractor_options={contractor_options}
            room_type_options={room_type_options}
            page_type={tab}
            is_properties={false}
            search_location={window.location}
          ></SearchModal>
          <Button
            prefix={<AddOutlinedIcon fontSize="small" />}
            outline
            size="small"
            onClick={() => {
              location.href = `/market_data/new?data_type=${tab}&redirect_to=${
                location.pathname + location.search
              }`
            }}
          >
            新規物件登録
          </Button>
        </div>
      </div>

      <Tab.Group defaultIndex={allTabs.indexOf(tab)} onChange={onChangeTab} className={'mt-2'}>
        <div className="flex text-sm overflow-x-auto whitespace-nowrap">
          <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
            <Tab
              className={`${styles['tab']} ${
                styles[tab === 'for_sale' ? 'true' : 'false']
              } border-l-[1px]`}
            >
              周辺売買事例
            </Tab>
            <Tab className={`${styles['tab']} ${styles[tab === 'for_rent' ? 'true' : 'false']}`}>
              周辺賃貸事例
            </Tab>
          </Tab.List>
        </div>
      </Tab.Group>
      <Table borderLine={false} topScrollbar={window.innerWidth > 768} scrollDep={market_datum}>
        <thead className="bg-gray-150 text-sm sticky top-0">
          <tr className="whitespace-nowrap">
            {columns[tab].map((column) => (
              <th scope="col" className="py-2 px-4 font-medium" key={`th-${column}`}>
                {columnNames[column]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {market_datum.map((marketDatum, rowIndex) => (
            <tr
              className={
                'whitespace-nowrap hover:bg-targetLine ' +
                (rowIndex % 2 ? 'bg-gray-light' : 'bg-white')
              }
              key={`market-datum-${marketDatum['no']}`}
            >
              {columns[tab].map((column) =>
                column === 'attached_file' && attached_files[marketDatum.id] ? (
                  <td
                    scope="col"
                    className="py-2 px-4 font-medium"
                    key={`market-datum-td-${marketDatum['no']}-${column}`}
                  >
                    {formatColumn(marketDatum, column, attached_files[marketDatum.id])}
                  </td>
                ) : (
                  <td className={['text-sm'].join(' ')}>
                    <a
                      href={`/market_data/${marketDatum.hashid}?redirect_to=${
                        location.pathname + location.search
                      }`}
                      className="block w-full h-full py-2 px-4"
                    >
                      {formatColumn(marketDatum, column, attached_files[marketDatum.id])}
                    </a>
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Base>
  )
}

const columns = {
  for_sale: [
    'no',
    'name',
    'address',
    'property_type',
    'building_type_text',
    'status_text',
    'current_rent',
    'current_noi',
    'full_prospective_rent',
    'full_prospective_noi',
    'sale_price',
    'sale_prospective_yield',
    'sale_prospective_noi_yield',
    'contract_prospective_noi_yield',
    'building_price',
    'prospective_yield',
    'area_m3',
    'area_m3_tsubo',
    'effective_area',
    'effective_area_tsubo',
    'total_floor_area',
    'total_floor_area_tsubo',
    'occupied_area',
    'occupied_area_tsubo',
    'floor_plan',
    'floors',
    'floor',
    'building_structure_short',
    'build_date',
    'contractor',
    'number_of_units',
    'transportation_names',
    'road_access_names',
    'elevator',
    'auto_lock',
    'publication_date',
    'contract_date',
    'attached_file',
    'user_name_with_department',
  ],
  for_rent: [
    'no',
    'name',
    'address',
    'property_type',
    'building_type_text',
    'status_text',
    'rent',
    'maintenance_fee',
    'rent_maintenance_fee',
    'rent_maintenance_fee_unit_price',
    'rent_maintenance_fee_unit_price_tsubo',
    'security_deposit',
    'key_money',
    'area_m3',
    'area_m3_tsubo',
    'effective_area',
    'effective_area_tsubo',
    'total_floor_area',
    'total_floor_area_tsubo',
    'occupied_area',
    'occupied_area_tsubo',
    'floor_plan',
    'floors',
    'floor',
    'build_date',
    'contractor',
    'location_of_division',
    'orientation_text',
    'room_type',
    'building_structure_short',
    'transportation_names',
    'road_access_names',
    'elevator',
    'auto_lock',
    'balcony',
    'free_internet',
    'furniture_and_appliances',
    'publication_date',
    'contract_date',
    'attached_file',
    'user_name_with_department',
  ],
}

const columnNames = {
  no: 'No',
  name: '物件名',
  address: '所在地',
  property_type: '物件種目',
  building_type_text: '物件タイプ',
  status_text: '状態',
  floor_plan: '間取り',
  building_structure_short: '構造',
  transportation_names: '交通機関',
  road_access_names: '道路',
  elevator: 'エレベーター',
  auto_lock: 'オートロック',
  publication_date: '掲載日',
  contract_date: '成約日',
  attached_file: '関連資料',
  user_name_with_department: '登録者',
  current_rent: '現況賃料(税込)/月額',
  current_noi: '現況NOI/月額',
  full_prospective_rent: '満室想定賃料(税込)/月額',
  full_prospective_noi: '満室想定NOI/月額',
  sale_price: '売出価格',
  sale_prospective_yield: '売出表面利回り',
  sale_prospective_noi_yield: '売出NOI利回り',
  building_price: '成約価格',
  prospective_yield: '成約表面利回り',
  contract_prospective_noi_yield: '成約NOI利回り',
  floors: '建物階数',
  floor: '所在階',
  number_of_units: '総戸数',
  rent: '賃料',
  maintenance_fee: '管理費',
  rent_maintenance_fee: '賃料（管理費込み）',
  rent_maintenance_fee_unit_price: '賃料（管理費込み）㎡単価',
  rent_maintenance_fee_unit_price_tsubo: '賃料（管理費込み）坪単価',
  security_deposit: '敷金',
  key_money: '礼金',
  area_m3: '敷地面積 (㎡)',
  area_m3_tsubo: '敷地面積 (坪)',
  effective_area: '有効敷地面積 (㎡)',
  effective_area_tsubo: '有効敷地面積 (坪)',
  total_floor_area: '延床面積 (㎡)',
  total_floor_area_tsubo: '延床面積 (坪)',
  occupied_area: '専有面積 (㎡)',
  occupied_area_tsubo: '専有面積 (坪)',
  build_date: '竣工年月',
  contractor: '施工会社',
  location_of_division: '部屋番号',
  orientation_text: '開口向き',
  room_type: '部屋タイプ',
  balcony: 'バルコニー',
  free_internet: 'インターネット無料',
  furniture_and_appliances: '家具家電',
}
const formatter = new Intl.NumberFormat('ja-JP')

const formatColumn = (marketDatum: MarketDatum, column: string, attachedFile) => {
  const value = marketDatum[column]

  if (
    [
      'building_price',
      'rent',
      'maintenance_fee',
      'rent_maintenance_fee',
      'rent_maintenance_fee_unit_price',
      'rent_maintenance_fee_unit_price_tsubo',
      'security_deposit',
      'key_money',
      'current_rent',
      'current_noi',
      'full_prospective_rent',
      'full_prospective_noi',
      'sale_price',
    ].includes(column) &&
    (value || value === 0)
  ) {
    return `${formatter.format(value)}円`
  } else if (column === 'attached_file' && attachedFile) {
    return (
      <a className="text-primary" href={attachedFile.url} target="_blank">
        {attachedFile.filename}
      </a>
    )
  } else if (
    [
      'prospective_yield',
      'sale_prospective_yield',
      'sale_prospective_noi_yield',
      'contract_prospective_noi_yield',
    ].includes(column) &&
    value
  ) {
    return `${formatter.format(Math.round(value * 10000) / 100)}%`
  } else if (
    ['area_m3', 'effective_area', 'total_floor_area', 'occupied_area'].includes(column) &&
    value
  ) {
    return `${cammedFormat(value, 2)}㎡`
  } else if (
    [
      'area_m3_tsubo',
      'effective_area_tsubo',
      'total_floor_area_tsubo',
      'occupied_area_tsubo',
    ].includes(column) &&
    value
  ) {
    return `${cammedFormat(value, 2)}坪`
  } else if (['number_of_units'].includes(column) && value) {
    return `${value}戸`
  } else if (['walk', 'bus'].includes(column) && (value || value === 0)) {
    return `${value}分`
  } else if (column === 'transportation_names') {
    return marketDatum[column]?.length > 0 ? (
      <>
        {marketDatum[column].map((name, i) => (
          <span key={`transportation-${i}`}>
            {i === 0 ? (
              name
            ) : (
              <>
                <br />
                {name}
              </>
            )}
          </span>
        ))}
      </>
    ) : (
      <span className="block w-full h-full py-2 px-4"></span>
    )
  } else if (column === 'road_access_names') {
    return marketDatum[column].length > 0 ? (
      <>
        {marketDatum[column].map((name, i) => (
          <span key={`road_access-${i}`}>
            {i === 0 ? (
              name
            ) : (
              <>
                <br />
                {name}
              </>
            )}
          </span>
        ))}
      </>
    ) : (
      <span className="block w-full h-full py-2 px-4"></span>
    )
  } else if (column === 'building_type_text') {
    return (
      marketDatum['custom_building_type'] ||
      (marketDatum['building_type'] ? marketDatum['building_type_text'] : undefined)
    )
  } else if (column === 'floor' && value) {
    return marketDatum['floor_type_text']
      ? `${marketDatum['floor_type_text']}${value}階`
      : `${value}階`
  } else {
    return value || <span className="block w-full h-full py-2 px-4"></span>
  }
}

const editPath = (marketDatum, property, tabIndex) => {
  return `/market_data/${marketDatum['hashid']}/edit?property_id=${property.hashid}&tab_index=${tabIndex}`
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

export default Index
