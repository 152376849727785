import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton } from '@/components/Button'
import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/MarketData'
import Base from '@/components/Layout/Base'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import { MarketDatum } from '@/types/marketDatum'
import { User } from '@/types/user'
import { detectLargeScreen } from '@/utils/screen'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { useJsApiLoader } from '@react-google-maps/api'
import * as React from 'react'

type Props = {
  current_user: User
  google_maps_api_key: string
  polygons_api_base_url: string
  market_datum: MarketDatum
  transportation_display: string[]
  road_access_display: string[]
  attached_file_base64_text_image?: string
  attached_file_base64_text_pdf?: string
}

const Index: React.FC<Props> = ({
  current_user,
  google_maps_api_key,
  polygons_api_base_url,
  market_datum,
  transportation_display,
  road_access_display,
  attached_file_base64_text_image,
  attached_file_base64_text_pdf,
}) => {
  const title = '周辺事例詳細'

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const mapRef = React.useRef<MapHandles>(null)
  const largeScreen = detectLargeScreen()
  const imagePreview = attached_file_base64_text_image || ''
  const pdfPreview = attached_file_base64_text_pdf || ''
  const fileViewer = (
    <>
      {imagePreview && (
        <div className="relative">
          <embed
            className="w-full mt-4"
            src={imagePreview}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      )}
      {pdfPreview && (
        <div className="relative mt-3 w-full h-[80vh]">
          <embed
            className="w-full h-full mt-4 border-b border-gray-700"
            src={pdfPreview + '#view=Fit'}
            style={{ width: '100%', height: '95%' }}
          />
        </div>
      )}
    </>
  )
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
    >
      <div className={'text-right w-full mt-4'}>
        <AnchorButton
          className="text-primary-font"
          prefix={<BorderColorOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/market_data/${market_datum.hashid}/edit?redirect_to=/market_data/${market_datum.hashid}`}
        >
          <span className="hidden md:inline">周辺事例編集</span>
        </AnchorButton>
      </div>
      <div className="pb-20 mt-4 lg:flex gap-4">
        <div className="w-full">
          <div className={'w-full max-w-2/3'}>
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              基本情報
            </div>
            <Table className="border-t border-b border-[#3885B0]">
              <Row label={<Th left={true}>事例種別</Th>}>
                <Td>{market_datum.data_type === 'for_sale' ? '周辺売買事例' : '周辺賃貸事例'}</Td>
              </Row>

              <Row label={<Th left={true}>物件種目</Th>}>
                <Td>{market_datum.property_type}</Td>
              </Row>

              <Row label={<Th left={true}>物件タイプ</Th>}>
                <Td>
                  {market_datum.building_type &&
                    `${
                      market_datum.custom_building_type
                        ? market_datum.custom_building_type
                        : market_datum.building_type_text
                    }`}
                </Td>
              </Row>

              <Row label={<Th left={true}>状態</Th>}>
                <Td>{market_datum.status_text}</Td>
              </Row>

              <Row label={<Th left={true}>所在地（住居表示）</Th>}>
                <Td>{market_datum.address}</Td>
              </Row>
            </Table>
          </div>
          <div className="lg:hidden my-4">
            {isLoaded && !largeScreen && (
              <GoogleMapViewer
                polygons_api_base_url={polygons_api_base_url}
                current_user={current_user}
                mode="market_datum"
                lat={market_datum.lat}
                lng={market_datum.lng}
                propertyLat={Number(market_datum.lat)}
                propertyLng={Number(market_datum.lng)}
                ref={mapRef}
                style={mapStyle}
                csrfToken={csrfToken.content}
              />
            )}
            {!largeScreen && (
              <>
                {imagePreview && (
                  <div className="relative">
                    <embed
                      className="mt-4"
                      src={imagePreview}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                )}
                {/* スマホなどの画面ではPDFが表示されないためプレビューはなし */}
                {pdfPreview && (
                  <div className="relative">
                    <p className="p-2 text-xs">
                      ※スマートフォンやタブレットPCの場合はPDFファイルのプレビューは表示されません
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={'w-full max-w-2/3 mt-4'}>
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              事例情報
            </div>
            <Table className="border-t border-b border-[#3885B0]">
              <Row label={<Th left={true}>交通情報</Th>}>
                <Td>
                  {transportation_display.map((item, index) => (
                    <span className={'my-1 w-full'} key={`transportation-${index}`}>
                      {item}
                    </span>
                  ))}
                </Td>
              </Row>
              <Row label={<Th left={true}>道路</Th>}>
                <Td>
                  {road_access_display.map((item, index) => (
                    <span className={'my-1 w-full'} key={`road_access-${index}`}>
                      {item}
                    </span>
                  ))}
                </Td>
              </Row>

              <Row label={<Th left={true}>掲載日</Th>}>
                <Td>{market_datum.publication_date}</Td>
              </Row>

              <Row label={<Th left={true}>成約日</Th>}>
                <Td>{market_datum.contract_date}</Td>
              </Row>

              <Row label={<Th left={true}>物件名</Th>}>
                <Td>{market_datum.name}</Td>
              </Row>

              <Row label={<Th left={true}>敷地面積</Th>}>
                <Td>
                  {market_datum.area_m3 &&
                    `${market_datum.area_m3}㎡ ( ${market_datum.area_m3_tsubo}坪 ) `}
                </Td>
              </Row>

              <Row label={<Th left={true}>有効敷地面積</Th>}>
                <Td>
                  {market_datum.effective_area &&
                    `${market_datum.effective_area}㎡ ( ${market_datum.effective_area_tsubo}坪 ) `}
                </Td>
              </Row>

              <Row label={<Th left={true}>延床面積</Th>}>
                <Td>
                  {market_datum.total_floor_area &&
                    `${market_datum.total_floor_area}㎡ ( ${market_datum.total_floor_area_tsubo}坪 ) `}
                </Td>
              </Row>

              <Row label={<Th left={true}>専有面積</Th>}>
                <Td>
                  {market_datum.occupied_area &&
                    `${market_datum.occupied_area}㎡ ( ${market_datum.occupied_area_tsubo}坪 ) `}
                </Td>
              </Row>

              <Row label={<Th left={true}>建物階数</Th>}>
                <Td>{market_datum.floors && market_datum.floors}</Td>
              </Row>

              <Row label={<Th left={true}>所在階</Th>}>
                <Td>
                  {market_datum.floor &&
                    (market_datum.floor_type_text
                      ? `${market_datum.floor_type_text}${market_datum.floor}階`
                      : `${market_datum.floor}階`)}
                </Td>
              </Row>

              <Row label={<Th left={true}>総戸数</Th>}>
                <Td>{market_datum.number_of_units && `${market_datum.number_of_units}戸`}</Td>
              </Row>

              <Row label={<Th left={true}>間取り</Th>}>
                <Td>{`${market_datum.floor_plan}`}</Td>
              </Row>

              <Row label={<Th left={true}>構造</Th>}>
                <Td>
                  {market_datum.building_structure_short &&
                    `${market_datum.building_structure_short}`}
                </Td>
              </Row>

              <Row label={<Th left={true}>竣工年月</Th>}>
                <Td>{market_datum.build_date && `${market_datum.build_date}`}</Td>
              </Row>

              <Row label={<Th left={true}>施工会社</Th>}>
                <Td>{market_datum.contractor && `${market_datum.contractor}`}</Td>
              </Row>

              <Row label={<Th left={true}>エレベーター</Th>}>
                <Td>{`${market_datum.elevator}`}</Td>
              </Row>

              <Row label={<Th left={true}>オートロック</Th>}>
                <Td>{`${market_datum.auto_lock}`}</Td>
              </Row>
              {market_datum.data_type === 'for_sale' && (
                <>
                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          現況賃料(税込)/月額
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.current_rent_0 && `${market_datum.current_rent_0}`}
                      </Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          現況NOI/月額
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.current_noi_0 && `${market_datum.current_noi_0}`}
                      </Td>
                    </Row>
                  </div>

                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          満室想定賃料(税込)/月額{' '}
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.full_prospective_rent_0 &&
                          `${market_datum.full_prospective_rent_0}`}
                      </Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          満室想定NOI/月額
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.full_prospective_noi_0 &&
                          `${market_datum.full_prospective_noi_0}`}
                      </Td>
                    </Row>
                  </div>

                  <Row label={<Th left={true}> 売出価格 </Th>}>
                    <Td>{market_datum.sale_price_0 && `${market_datum.sale_price_0}`}</Td>
                  </Row>

                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          売出表面利回り
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.sale_prospective_yield &&
                          `${(
                            Math.round(market_datum.sale_prospective_yield * 10000) / 100
                          ).toFixed(2)}%`}
                      </Td>
                    </Row>

                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          売出NOI利回り
                        </Th>
                      }
                    >
                      <Td>
                        {market_datum.sale_prospective_noi_yield &&
                          `${(
                            Math.round(market_datum.sale_prospective_noi_yield * 10000) / 100
                          ).toFixed(2)}%`}
                      </Td>
                    </Row>
                  </div>

                  <Row label={<Th left={true}> 成約価格 </Th>}>
                    <Td>{market_datum.building_price_0 && `${market_datum.building_price_0}`}</Td>
                  </Row>

                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          成約表面利回り
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {market_datum.prospective_yield &&
                          `${(Math.round(market_datum.prospective_yield * 10000) / 100).toFixed(
                            2
                          )}%`}
                      </Td>
                    </Row>

                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          {' '}
                          成約NOI利回り
                        </Th>
                      }
                    >
                      <Td>
                        {market_datum.contract_prospective_noi_yield &&
                          `${(
                            Math.round(market_datum.contract_prospective_noi_yield * 10000) / 100
                          ).toFixed(2)}%`}
                      </Td>
                    </Row>
                  </div>
                </>
              )}
              <Row label={<Th left={true}>備考</Th>}>
                <Td> {market_datum.note && `${market_datum.note}`}</Td>
              </Row>
            </Table>
          </div>
        </div>
        {isLoaded && (
          <div className="w-2/3 hidden lg:block">
            <GoogleMapViewer
              current_user={current_user}
              mode="market_datum"
              polygons_api_base_url={polygons_api_base_url}
              csrfToken={csrfToken.content}
              lat={market_datum.lat}
              lng={market_datum.lng}
              propertyLat={Number(market_datum.lat)}
              propertyLng={Number(market_datum.lng)}
              ref={mapRef}
              style={mapStyle}
            />
            {largeScreen && fileViewer}
          </div>
        )}
      </div>
    </Base>
  )
}

const mapStyle = {
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
}

export default Index
